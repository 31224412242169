<template>
  <!-- <div class="container"> -->
  <div class="content">
    <!-- <h1>Lanpay rates history</h1> -->
    <div class="sum">
      <div class="sum__dropdowns">
        <b-dropdown
          id="month"
          :text="selected ? options[selected - 1].text : 'Select month'"
          class="m-2"
          variant="bd-light"
        >
          <b-dropdown-item
            v-for="month in options"
            :key="month.value"
            :active="selected ? month.value === options[selected - 1].value : false"
            @click="onClick(month)"
          >
            {{ month.text }}
          </b-dropdown-item>
        </b-dropdown>
        <!-- <div class="select-month">
          <b-form-select
            class="select-month__select"
            v-model="selected"
            :options="options"
            @change="getData"
          >
            Select month
          </b-form-select>
        </div> -->
        <b-dropdown
          id="month"
          :text="selectedCalc.value"
          class="m-2"
          variant="bd-light"
        >
          <b-dropdown-item
            v-for="option in optionsCalc"
            :key="option.currencyId"
            :active="selectedCalc.currencyId === option.currencyId"
            @click="onClickUsdt(option)"
          >
            {{ option.value }}
          </b-dropdown-item>
        </b-dropdown>

      </div>
      <div class="add-content m-2">
        <b-button variant="primary" class="add-content__btn" @click.stop="modal = !modal">Добавить</b-button>
      </div>
      
      <!-- <div>
        <div class="sum__block">
          <div class="sum__input">
            <div class="sum__row-btn">
              <b-dropdown
                id="month"
                :text="selectedCalc.value"
                class="m-md-2"
                variant="bd-light"
              >
                <b-dropdown-item
                  v-for="option in optionsCalc"
                  :key="option.currencyId"
                  :active="selectedCalc.currencyId === option.currencyId"
                  @click="onClickUsdt(option)"
                >
                  {{ option.value }}
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
        </div>
      </div> -->
    </div>
      <!-- striped -->
    <b-table
      striped
      borderless
      hover
      :items="items"
      :fields="fields"
      :perPage="perPage"
      :currentPage="currentPage"
    >
    </b-table>
    <div v-if="!checkData" class="nodata">There is no data</div>
    <b-pagination
      v-if="checkData"
      class="pagination"
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="my-table"
    ></b-pagination>
    <!--  -->
    <!--  -->
    <!--  -->
    <b-modal
      id="modal-prevent-closing"
      ref="modal"
      title="Добавить данные обмена"
      v-model="modal"
      ok-title="Добавить"
      cancel-title="Отмена"
      hide-header-close
      @ok="handleOk"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
          label="Имя Агента"
          label-for="name-input"
          :class="{'input-error' : form.name.error}"
        >
          <b-form-input
            id="name-input"
            v-model="form.name.value"
            @focus="form.name.error = false"
            required
          ></b-form-input>
          <span v-if="form.name.error" class="input-message">Поле обязательно для заполнения</span>
        </b-form-group>
        <b-form-group
          label="Сумма RUB"
          label-for="rub-input"
          :class="{'input-error' : form.rub.error}"
        >
          <b-form-input
            id="rub-input"
            type="number"
            @focus="form.rub.value === 0 ? form.rub.value = '' : form.rub.value = form.rub.value; form.rub.error = false"
            @blur="form.rub.value === '' ? form.rub.value = 0 : form.rub.value = form.rub.value"
            v-model="form.rub.value"
            required
          ></b-form-input>
          <span v-if="form.rub.error" class="input-message">Значение поля не должно быть равно 0</span>
        </b-form-group>
        <b-form-group
          label="Сумма USDT"
          label-for="usdt-input"
          :class="{'input-error' : form.usdt.error}"
        >
          <b-form-input
            id="usdt-input"
            type="number"
            @focus="form.usdt.value === 0 ? form.usdt.value = '' : form.usdt.value = form.usdt.value; form.usdt.error = false"
            @blur="form.usdt.value === '' ? form.usdt.value = 0 : form.usdt.value = form.usdt.value"
            v-model="form.usdt.value"
            required
          ></b-form-input>
          <span v-if="form.usdt.error" class="input-message">Значение поля не должно быть равно 0</span>
        </b-form-group>
        <b-form-group
          label="Курс"
          label-for="well-input"
          invalid-feedback="Заполните пожалуйста имя агента"
        >
          <b-form-input
            id="well-input"
            type="number"
            v-model="calculatedCourse"
            disabled
          ></b-form-input>
        </b-form-group>
      </form>
    </b-modal>
    <b-modal
      id="modal-prevent-closing"
      ref="modal-success"
      title="Данные успешно сохранены"
      v-model="modalSuccess"
      ok-title="OK"
      ok-only
      hide-header-close
      @ok="modalSuccess = false"
    >
      <p>Итоговая таблица будет обновлена в течении 4х часов, с учетом новых данных.</p>
    </b-modal>
  </div>
  <!-- </div> -->
</template>

<script>

import axios from 'axios';

export default {
  name: 'Home',
  data: () => ({
    modal: false,
    modalSuccess: false,
    form: {
      name: {
        value: '',
        error: false,
      },
      rub: {
        value: 0,
        error: false,
      },
      usdt: {
        value: 0,
        error: false,
      },
    },
    fields: [
      {
        key: 'start_day',
        label: 'Date',
      },
      {
        key: 'rate_avg',
        label: 'Avg',
        tdClass: 'text-bold',
        formatter: value => {
          if(value === 0) {
            value = '-'
            return value
          } else {
            return value
          }
        }
      },
      {
        key: 'rate_n',
        label: 'Night',
        formatter: value => {
          if(value === 0) {
            value = '-'
            return value
          } else {
            return value
          }
        }
      },
      {
        key: 'rate_m',
        label: 'Morning',
        formatter: value => {
          if(value === 0) {
            value = '-'
            return value
          } else {
            return value
          }
        }
      },
      {
        key: 'rate_d',
        label: 'Day',
        formatter: value => {
          if(value === 0) {
            value = '-'
            return value
          } else {
            return value
          }
        }
      },
      {
        key: 'rate_e',
        label: 'Evening',
        formatter: value => {
          if(value === 0) {
            value = '-'
            return value
          } else {
            return value
          }
        }
      },
      {
        key: 'rate_n',
        label: 'Afternoon',
        formatter: value => {
          if(value === 0) {
            value = '-'
            return value
          } else {
            return value
          }
        }
      },
    ],
    items: null,
    interval: 1000,
    perPage: 5,
    currentPage: 1,
    rows: null,
    selected: null,
    selectedCalc: { value: 'RUB-USDT', text: 'RUB-USDT', currencyId: 1 },
    lastAvg: null,
    transAmount: '',
    oldTransAmount: null,
    resultAmount: null,
    showResult: false,
    result: null,
    options: [
      { value: 1, text: 'January' },
      { value: 2, text: 'February' },
      { value: 3, text: 'March' },
      { value: 4, text: 'April' },
      { value: 5, text: 'May' },
      { value: 6, text: 'June' },
      { value: 7, text: 'July' },
      { value: 8, text: 'August' },
      { value: 9, text: 'September' },
      { value: 10, text: 'October' },
      { value: 11, text: 'November' },
      { value: 12, text: 'December' },
    ],
    optionsCalc: [
      { value: 'RUB-USDT', text: 'RUB-USDT', currencyId: 1 },
      // { value: 'EUR-USDT', text: 'EUR-USDT', currencyId: 1 },
      { value: 'KZT-USDT', text: 'KZT-USDT', currencyId: 6 },
      { value: 'KGS-USDT', text: 'KGS-USDT', currencyId: 7 },
      { value: 'UZS-USDT', text: 'UZS-USDT', currencyId: 5 },
    ],
    mokData: [
      {
        rate_n: 94.00,
        rate_m: 94.25,
        rate_d: 94.50,
        rate_e: 94.25,
        rate_avg: 94.25,
        start_day: "2022-04-12 00:00:00"
      },
      {
        rate_n: 92.35,
        rate_m: 91.80,
        rate_d: 92.1,
        rate_e: 92.98,
        rate_avg: 92.1,
        start_day: "2022-04-11 00:00:00"
      },
      {
        rate_n: 91.59,
        rate_m: 91.1,
        rate_d: 91.09,
        rate_e: 90.35,
        rate_avg: 91.09,
        start_day: "2022-04-10 00:00:00"
      },
      {
        rate_n: 93.57,
        rate_m: 92.1,
        rate_d: 91.78,
        rate_e: 91.63,
        rate_avg: 91.78,
        start_day: "2022-04-09 00:00:00"
      },
      {
        rate_n: 91.80,
        rate_m: 91.90,
        rate_d: 92.1,
        rate_e: 93.25,
        rate_avg: 92.1,
        start_day: "2022-04-08 00:00:00"
      },
      {
        rate_n: 95.9,
        rate_m: 95.8,
        rate_d: 95.75,
        rate_e: 96.5,
        rate_avg: 95.75,
        start_day: "2022-04-07 00:00:00"
      },
      {
        rate_n: 96.99,
        rate_m: 96.4,
        rate_d: 96.79,
        rate_e: 96.45,
        rate_avg: 96.79,
        start_day: "2022-04-06 00:00:00"
      },
      {
        rate_n: 95.18,
        rate_m: 95.15,
        rate_d: 95.75,
        rate_e: 95.95,
        rate_avg: 95.75,
        start_day: "2022-04-05 00:00:00"
      },
      {
        rate_n: 94.5,
        rate_m: 94.4,
        rate_d: 94.5,
        rate_e: 94.6,
        rate_avg: 94.5,
        start_day: "2022-04-04 00:00:00"
      },
      {
        rate_n: 95.39,
        rate_m: 95.19,
        rate_d: 95.29,
        rate_e: 95.07,
        rate_avg: 95.29,
        start_day: "2022-04-03 00:00:00"
      },
      {
        rate_n: 95.92,
        rate_m: 95.3,
        rate_d: 96.25,
        rate_e: 95,
        rate_avg: 96.25,
        start_day: "2022-04-02 00:00:00"
      },
      {
        rate_n: 95.0,
        rate_m: 95.7,
        rate_d: 94.2,
        rate_e: 95.5,
        rate_avg: 95.5,
        start_day: "2022-04-01 00:00:00"
      },
    ]
  }),
  methods: {
    handleSubmit() {
      console.log('SUBMIT')
    },
    handleOk(e) {
      e.preventDefault()
      this.clearFormErrors()
      if(this.form.name.value.length < 4) {
        this.form.name.error = true
      }
      if(this.form.rub.value === 0) this.form.rub.error = true
      if(this.form.usdt.value === 0) this.form.usdt.error = true

      if(!this.form.name.error && !this.form.rub.error && !this.form.usdt.error) {
        this.modal = false
        this.modalSuccess = true
      }
    },
    clearFormErrors() {
      this.form.name.error = false
      this.form.rub.error = false
      this.form.usdt.error = false
    },
    getUSDT() {
      // this.checkUSDT ? this.items = [] : this.getData()
      this.getData()
    },
    clientWidth() {
      if (window.screen.width < 475) {
        this.fields = [
          {
            key: 'start_day',
            label: 'Date',
          },
          {
            key: 'rate_avg',
            label: 'Avg',
            tdClass: 'text-bold',
            formatter: value => {
              if(value === 0) {
                value = '-'
                return value
              } else {
                return value
              }
            }
          },
        ]
      }
      // return window.screen.width
    },
    onClick(month) {
      this.selected = month.value
      this.getData()
    },
    onClickUsdt(option) {
      this.selectedCalc = option
      this.getUSDT()
    },
    activeUsdt() {
      return this.optionsCalc.find(el => el.value === this.selectedCalc.value).value
    },
    async getData() {
      // if(!this.checkUSDT) {
        const selectedUSDT = this.optionsCalc.find(el => el.value === this.selectedCalc.value)
        await axios.get(`https://api.lanpay.cc/api/binancio/rates?month=${this.selected}&currencyId=${selectedUSDT.currencyId}&page=1&results=30`)
          .then((res) => {
            const response = JSON.parse(JSON.stringify(res));
            // const response = {...res};
            // const data
            this.items = response.data.data
            // this.items.forEach(el => el.status = 'awesome')
            this.perPage = response.data.meta.per_page
            this.currentPage = response.data.meta.current_page
            
            // const date = new Date().getMonth();
            // if(this.currentPage === 1 && this.selected === date + 1){
            //   this.items.splice(0, 1)
            // }
            this.rows = response.data.meta.total
            this.lastAvg = response.data.data[0].rate_avg
            if(this.selected === 4) {
              this.items.push(...this.mokData)
            }
            this.items.forEach(el => {
              el.start_day = el.start_day.split(' ', 1).join('')
            });
          })
      // } else {
      //   this.items = []
      // }
    },
    checkSum () {
      this.showResult = true
      this.resultAmount = this.transAmount
      this.result = (this.transAmount / this.lastAvg).toFixed(2)
    },
    keyUp(e) {
      if(e.keyCode === 13) {
        this.checkSum()
      }
    },
    copyText () {
      this.$copyText(`Sum: ${this.resultAmount} RUB\nRate: ${this.lastAvg}\nTotal: ${this.result} USD`)
    }
  },
  computed: {
    // invalidName() {
    //   if(this.form.name.value.length > 0 && this.form.name.value.length < 4) {
    //     return 'Enter at least 4 characters.'
    //   }
    //   return '123'
    // },
    calculatedCourse() {
      return  this.form.rub.value / this.form.usdt.value  === Infinity ? 0 : (this.form.rub.value / this.form.usdt.value).toFixed(2) || 0
    },
    checkData() {
      return this.items?.length
    },
    missingValue() {
      return this.transAmount === 0 || this.transAmount === ''
    },
  },
  watch: {
    modal(val) {
      if(val === false) this.clearFormErrors()
    },
    transAmount(newValue) {
      if(newValue.length == 0) {
        this.showResult = false
      } else {
        setTimeout(() => {
          clearInterval(this.interval);
          if (newValue !== this.oldTransAmount) {
            this.interval = setInterval(() => {
              this.checkSum()
              this.oldTransAmount = newValue;
              clearInterval(this.interval);
            }, 1000);
          }
        }, 0);
      }
      // if(newValue.length == 0) {
      //   this.resultAmount = null
      //   // this.lastAvg = null
      //   this.result = null
      // }
    },
  },
  mounted() {
    this.selected = new Date().getMonth() + 1
    this.getData()
    this.clientWidth()
  }
}
</script>

<style>

#month button {
  border-color: #dee2e6;
}
#month button:hover {
  border-color: #7952b3;
  color: #7952b3;
}
#month button:focus {
  box-shadow: none;
}

#month button:after {
  margin-left: 10px;
}

input[type='number'] {
  -moz-appearance:textfield;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 16px;
}
.pagination {
  align-self: center;
}
.text-bold {
  font-weight: 600;
}
.sum {
  display: flex;
  justify-content: space-between;
  padding: 0 30px;
  margin: 30px 0;
}
.sum__block {
  display: flex;
  align-items: flex-end;
}
.sum__block button {
  align-self: end;
}
.sum__input {
  display: flex;
  flex-direction: column;
}
.sum__input label {
  text-align: left;
  margin-bottom: 5px;
}
.sum__row-btn {
  display: flex;
}
.sum__btn {
  padding: 10px 15px;
  /* margin-left: 15px; */
  border-radius: 10px;
  border-color: #d8e5f1;
  color: #2c3e50;
}
.sum__btn-disabled {
  /* margin-left: 15px; */
  color: #ccc !important;
  border-color: #ccc !important;
}
.select-month {
  display: flex;
  justify-content: flex-end;
}
.select-month__select {
  align-self: end;
  width: 150px;
  padding: 10px 15px;
  border-radius: 10px;
  color: #2c3e50;
  /* margin: 15px 20px; */
  text-align: right;
}
.add-content {
  margin-bottom: 20px;
  display: flex;
  padding: 0 30px;
}
.add-content__btn {
  /* color: #2c3e50 !important; */
  /* border-color: #d8e5f1 !important; */
}
.add-content__btn:hover {
  /* color: ; */
  /* background-color: #fff !important; */
  /* border-color: #2c3e50 !important; */
  /* border-color: #7952b3 !important; */
  /* color: #7952b3 !important; */
}
.add-content__btn:focus {
  box-shadow: none !important;
}
.nodata {
  margin: 30px 0;
  font-size: 20px;
}
.result {
  /* padding: 0 30px; */
  text-align: left;
  margin: 15px 0;
}
.result__rate {

}
.result__text {

}
.result__total {
  margin-bottom: 15px;
}
@media (max-width: 550px) {
  .sum {
    flex-direction: row;
    align-items: center;  
    padding: 0;
  }
  .sum__dropdowns {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .add-content {
    /* display: flex;
    justify-content: flex-end; */
    padding: 0;
  }
  .select-month {
    /* margin-top: 20px; */
  }
}
@media (max-width: 350px) {
  .sum {
    flex-direction: column;
    align-items: flex-start;
  }
  .select-month {
    margin-top: 20px;
  }
}
table thead {
  background-color: rgb(155, 193, 232);
	/* border-radius: 8px 0 0 8px;
  margin-bottom: 10px; */
}
table thead tr th:first-child {
	border-radius: 8px 0 0 8px;
}
table thead tr th:last-child {
	border-radius: 0 8px 8px 0;
}
table {
  color: #2c3e50 !important;
}
table tbody tr:nth-child(2n) {
  /* background-color: rgba(138, 19, 179, .3); */
  /* background-color: #b1cce7; */
  background-color: #d8e5f1;
}
/* table tbody tr:nth-child(2n+1) { */
  /* background-color: rgba(138, 19, 179, .3); */
  /* background-color: #d8e5f1; */
  /* background-color: #fff; */
/* } */
.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: transparent !important;
}
/* .table-striped > tbody > tr:nth-of-type(odd) > *:hover {
  --bs-table-accent-bg: #b1cce7 !important;
} */
table tbody tr:hover {
  background-color: #b1cce7;
  --bs-table-accent-bg: #b1cce7;
}

.table tbody tr td:first-child {
	border-radius: 8px 0 0 8px;
}
.table tbody tr td:last-child {
	border-radius: 0 8px 8px 0;
}

.modal-dialog input {
  border-color: #d8e5f1 !important;
  color: #2c3e50;
  margin-bottom: 10px;
}
.modal-dialog input:focus {
  box-shadow: none;
  border-color: #a9cef1 !important;
}


.input-error {
  position: relative !important;
}
.input-error input {
  border-color: rgb(206, 27, 27) !important;
}
.input-message {
  color: rgb(206, 27, 27) !important;
  font-size: 11px;
  position: absolute;
  bottom: -15px;
  left: 0;
}
</style>
