// import Vue from 'vue'
// import VueRouter from 'vue-router'

// Vue.use(VueRouter)

const routes = [
  {
    path: '/:page',
    name: 'Home',
    component: () => import('../components/Home'),
    meta: {
        layout: 'home page'
    },
  },
]

// const router = new VueRouter({
//   mode: 'history',
//   routes,
// })

export default routes
